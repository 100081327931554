import Hotjar from '@hotjar/browser'

export const heatmapMaker = () => {
    return {
        init: (trackingID: number | undefined, trackingVersion: number | undefined) => {
            if (!trackingID) {
                console.error('no heatmap tracking id provided')
                return
            }
            if (!trackingVersion) {
                console.error('no heatmap tracking version provided')
                return
            }

            Hotjar.init(trackingID, trackingVersion)
        },
    }
}
